// ? Note: '414 7th N St' has a space after 'St' in the result that we get from an API.
// ? So, added both in case it gets fixed in future
const COMMERCIAL_LOCATIONS = [
  '25 Consumer Square',
  '130th Place Between E & W Hangar Road',
  '180 N. 9th Street',
  '415 E Brighton Ave',
  '441 Commerce Dr',
  '4968 NY-23',
  '7200 Niagara Falls Blvd',
  '33 South Washington',
  '414 7th N St',
  '414 7th N St ',
  '209 EAST AVE',
];

const BOUNDARY_STYLES = {
  strokeColor: '#0a124a',
  strokeOpacity: 1.0,
  strokeWeight: 2.5,
  fillColor: '#0a124a',
  fillOpacity: 0,
};

export default { COMMERCIAL_LOCATIONS, BOUNDARY_STYLES };
