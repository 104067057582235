<template>
  <div id="app">
    <a class="button sr-only sr-only-focusable skip-link" href="#main">Skip to content</a>
    <SimpleHeader v-if="this.$route.name === 'mobile-tou'" />
    <Header
      v-if="
        this.navigation &&
        this.$route.name !== 'mobile-tou' &&
        this.$route.name &&
        !this.$route.name.includes('webscreens')
      "
    />
    <main id="main"><router-view /></main>
    <Footer
      v-if="this.navigation && this.$route.name && !this.$route.name.includes('webscreens')"
      :socialMediaLinks="socialMediaLinks"
      :footerLinks="footerLinks"
      :legalLinks="legalLinks"
      homepageAriaLabel="Electrify America Homepage"
      :logoSrc="logoSrc"
      lang="en"
    >
      <template #description>
        <p>Electrify America offers transformative, customer-centric infrastructure and energy management solutions.</p>
      </template>
    </Footer>
  </div>
</template>

<script>
import './assets/scss/base.scss';
import Header from '@/components/Header/Header.vue';
import SimpleHeader from '@/components/Header/HeaderSimple.vue';
import Footer from '@/components/Footer/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    SimpleHeader,
  },
  data() {
    return {
      navigation: null,
      socialMediaLinks: [
        {
          src: require('@/assets/images/Footer/facebook_white.png'),
          route: 'https://www.facebook.com/ElectrifyAmerica/',
          alt: 'Facebook',
        },
        {
          src: require('@/assets/images/Footer/instagram_white.png'),
          route: 'https://www.instagram.com/electrifyamerica/?hl=en',
          alt: 'Instagram',
        },
        {
          src: require('@/assets/images/Footer/x_white.png'),
          route: 'https://x.com/electrifyam?lang=en',
          alt: 'X',
        },
        {
          src: require('@/assets/images/Footer/linkedin_white.png'),
          route: 'https://www.linkedin.com/company/electrifyamerica/about/',
          alt: 'LinkedIn',
        },
        {
          src: require('@/assets/images/Footer/youtube_white.png'),
          route: 'https://www.youtube.com/channel/UC3GGcyuzYYyYmZ0hJKlBb6w?view_as=subscriber',
          alt: 'Youtube',
        },
      ],
      footerLinks: [
        {
          summary: 'Help',
          details: [
            {
              name: 'Glossary',
              route: 'glossary',
            },
            {
              name: 'FAQs',
              route: 'mobile-faq',
            },
            {
              name: 'Contact us',
              route: 'contact-us',
            },
            {
              name: 'HomeStation',
              route: 'homestation',
            },
          ],
        },
        {
          summary: 'Company',
          details: [
            {
              name: 'Careers',
              route: 'careers',
            },
            {
              name: 'Marketing opportunities',
              route: 'marketing-opportunities',
            },
            {
              name: 'News',
              route: 'https://media.electrifyamerica.com/en-us/',
            },
          ],
        },
        {
          summary: 'Solutions',
          details: [
            {
              name: 'Electrify Commerical',
              route: 'https://electrify-commercial.com/',
            },
            {
              name: 'Electrify Canada',
              route: 'https://www.electrify-canada.ca/',
            },
          ],
        },
      ],
      legalLinks: {
        levelOne: [
          {
            name: 'Privacy',
            route: 'privacy',
          },
          {
            name: 'Your Privacy Choices',
            class: 'optanon-show-settings',
            src: require('@/assets/images/Footer/privacyoptions.svg?external'),
            alt: '',
            button: true,
          },
          {
            name: 'Terms of use',
            route: 'terms',
          },
          {
            name: 'Whistleblower System',
            route: 'whistleblower',
          },
          {
            name: 'Social Media House Rules',
            route: 'social-media-house-rules',
          },
          {
            name: 'Planning Submissions',
            route: 'submissions',
          },
          {
            name: 'California Voluntary Carbon Market Disclosure (AB 1305)',
            route: 'california-voluntary-carbon-market-disclosure',
          },
        ],
        levelTwo: [
          {
            copyright: true,
            name: `&copy; ${new Date().getFullYear()} Electrify America`,
          },
          {
            support: true,
            name: 'Support',
            route: '1-833-632-2778',
          },
        ],
      },
      logoSrc: require('@/assets/images/Footer/logo-horizontal-on-white.svg?external'),
    };
  },
  created() {
    this.checkRoute();
  },
  methods: {
    checkRoute() {
      if (this.$route.name === 'CSR-2021-Q2-en') {
        this.navigation = false;
      } else {
        this.navigation = true;
      }
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
};
</script>
