const EventConnect = () => import('@/views/Contact/EventConnect.vue');
const ContactUs = () => import('@/views/Contact/ContactUs.vue');
const GreenCity = () => import('@/views/Contact/GreenCity.vue');
const MarketingOpportunities = () => import('@/views/Contact/MarketingOpportunities.vue');
const RealEstate = () => import('@/views/Contact/RealEstate.vue');
const Signup = () => import('@/views/Contact/Signup.vue');
const Submissions = () => import('@/views/Contact/Submissions.vue');

const routes = [
  {
    path: '/event-connect/',
    name: 'event-connect-en',
    pathToRegexpOptions: { strict: true },
    component: EventConnect,
  },

  {
    path: '/contact-us/',
    name: 'contact-us-en',
    pathToRegexpOptions: { strict: true },
    component: ContactUs,
  },
  {
    path: '/green-city/',
    name: 'green-city-en',
    pathToRegexpOptions: { strict: true },
    component: GreenCity,
  },
  {
    path: '/marketing-opportunities/',
    name: 'marketing-opportunities-en',
    pathToRegexpOptions: { strict: true },
    component: MarketingOpportunities,
  },
  {
    path: '/real-estate/',
    name: 'real-estate-en',
    pathToRegexpOptions: { strict: true },
    component: RealEstate,
  },
  {
    path: '/sign-up/',
    name: 'sign-up-en',
    pathToRegexpOptions: { strict: true },
    component: Signup,
  },
  {
    path: '/submissions/',
    name: 'submissions-en',
    pathToRegexpOptions: { strict: true },
    component: Submissions,
  },
];

const exportedRoutes = routes;
export default exportedRoutes;
